<template>
  <div>
    <h1>Universal Analytics Archives - Detailed Data</h1>
    <v-card class="my-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="2">
          <v-select
            outlined
            v-model="selectedTable"
            :items="tables"
            :item-text="(table) => table.text"
            item-value="value"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Choose table:"
            @input="tableChange"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            outlined
            :items="sites"
            v-model="selectedSite"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Choose site:"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <date-filter
            periodType="past-only"
            format="month"
            :initialValue="datesInitialValue"
            @valueChanged="setDates"
          ></date-filter>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            v-model="selectedDimension"
            :items="dimensions"
            :item-text="(table) => table.text"
            item-value="value"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Choose dimension to search in:"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche"
            single-line
            hide-details
          ></v-text-field
        ></v-col>
        <v-col cols="2">
          <v-btn
            @click="debouncedUpdateData"
            class="pt-7 pb-6 mt-0 my-1"
            color="primary"
          >
            Valider
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-3">
        <v-col>
          <v-data-table
            ref="table"
            :page="options.page"
            :pageCount="numberOfPages"
            :headers="headers"
            :items="items"
            :sort-by="null"
            :sort-desc="true"
            :options.sync="options"
            :server-items-length="options.total"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            :items-per-page="options.itemsPerPage"
            loading-text="Chargement"
            no-data-text="Pas de données"
            no-results-text="Pas de résultats"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import DateFilter from "@/components/common/filters/DateFilter";
import debounce from "lodash/debounce";
import { saveRmraView } from "@/utils/rmr-analytics";
import {
  prettyNbs,
  // round2Dec,
  // truncate,
  // ltruncate,
  // formatTime,
} from "@/utils/formatting";

export default {
  name: "UAData",
  components: {
    DateFilter,
  },
  data() {
    return {
      selectedDimension: null,
      search: "",
      sites: [],
      selectedSite: [],
      defaultSelectedSites: [2],
      dimensions: [],
      tablesWithDimensions: {
        ua__site: [],
        ua__path_recipe: ["path"],
        ua__landing_page: ["landing_page"],
        ua__section_subsection_type_path_clics_affiliation: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__path_category: ["path", "category"],
        ua__section_subsection_type: ["section", "subsection", "content_type"],
        ua__source: ["source"],
        ua__section_subsection_type_path: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__country_city: ["country", "city"],
        ua__section_subsection_type_plays_video: [
          "section",
          "subsection",
          "content_type",
        ],
        ua__user_status: ["user_status"],
        ua__channel_group: ["channel_group"],
        ua__campaign: ["campaign"],
        ua__path_recipe_diets_ingredients: [
          "path",
          "diets",
          "ingredients",
          "ingredients_ids",
        ],
        ua__section_subsection_type_clics_affiliation: [
          "section",
          "subsection",
          "content_type",
        ],
        ua__country: ["country"],
        ua__medium: ["medium"],
        ua__device: ["device"],
        ua__section_subsection_type_path_plays_video: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__ops: ["ops"],
        ua__levels3456: ["level_3", "level_4", "level_5", "level_6"],
        ua__path_level12: ["path", "level_1", "level_2"],
        ua__path_section: ["path", "section"],
        ua__path: ["path"],
        ua__source_medium: ["source", "medium", "source_medium"],
        ua__author: ["author"],
        ua__age: ["age"],
        ua__gender: ["gender"],
      },
      tables: [
        {
          text: "Pack commun: site",
          value: "ua__site",
        },
        {
          text: "Pack commun: age",
          value: "ua__age",
        },
        {
          text: "Pack commun: gender",
          value: "ua__gender",
        },
        {
          text: "Pack commun: device",
          value: "ua__device",
        },
        {
          text: "Pack commun: path",
          value: "ua__path",
        },
        {
          text: "Pack commun: campaign",
          value: "ua__campaign",
        },
        {
          text: "Pack commun: country",
          value: "ua__country",
        },
        {
          text: "Pack commun: country_city",
          value: "ua__country_city",
        },
        {
          text: "Pack commun: source",
          value: "ua__source",
        },
        {
          text: "Pack commun: medium",
          value: "ua__medium",
        },
        {
          text: "Pack commun: source_medium",
          value: "ua__source_medium",
        },
        {
          text: "Pack commun: channel_group",
          value: "ua__channel_group",
        },
        {
          text: "Les numériques, CNet et ZDnet: section_subsection_type",
          value: "ua__section_subsection_type",
        },
        {
          text: "Les numériques, CNet et ZDnet: section_subsection_type_path",
          value: "ua__section_subsection_type_path",
        },
        {
          text: "Les numériques et CNet: section_subsection_type_clics_affiliation",
          value: "ua__section_subsection_type_clics_affiliation",
        },
        {
          text: "Les numériques: section_subsection_type_path_clics_affiliation",
          value: "ua__section_subsection_type_path_clics_affiliation",
        },
        {
          text: "Les numériques: section_subsection_type_plays_video",
          value: "ua__section_subsection_type_plays_video",
        },
        {
          text: "Les numériques: section_subsection_type_path_plays_video",
          value: "ua__section_subsection_type_path_plays_video",
        },
        {
          text: "Docti: landing_page",
          value: "ua__landing_page",
        },
        {
          text: "Docti et Netmums: author",
          value: "ua__author",
        },
        {
          text: "Netmums: path_section",
          value: "ua__path_section",
        },
        {
          text: "Marmiton: ops",
          value: "ua__ops",
        },
        {
          text: "Marmiton: levels3456",
          value: "ua__levels3456",
        },
        {
          text: "Marmiton: path_recipe",
          value: "ua__path_recipe",
        },
        {
          text: "Marmiton: path_recipe_diets_ingredients",
          value: "ua__path_recipe_diets_ingredients",
        },
        {
          text: "Science & Vie: user_status",
          value: "ua__user_status",
        },
        {
          text: "Grazia.fr: path_level12",
          value: "ua__path_level12",
        },
        {
          text: "Grazia a l'étranger: path_category",
          value: "ua__path_category",
        },
      ],
      selectedTable: "ua__site",
      selectedDates: [],
      datesInitialValue: ["2019-07", "2023-07"],
      items: [],
      headers: [],
      headers_dimensions_static: [
        {
          text: "site id",
          value: "site_id",
          align: "end",
          sortable: true,
        },
        // {
        //   text: "site name",
        //   value: "site_name",
        //   align: "start",
        //   sortable: false,
        // },
        {
          text: "site domain",
          value: "site_domain",
          align: "start",
          sortable: false,
        },
        {
          text: "view id",
          value: "view_id",
          align: "start",
          sortable: false,
        },
        // {
        //   text: "frequency",
        //   value: "frequency",
        //   align: "start",
        //   sortable: false,
        // },
        // {
        //   text: "date",
        //   value: "date",
        //   align: "start",
        //   sortable: false,
        // },
        {
          text: "month",
          value: "month",
          align: "start",
          sortable: false,
        },
      ],
      headers_metrics: [
        {
          text: "views",
          value: "views",
          align: "end",
          sortable: true,
        },
        {
          text: "sessions",
          value: "sessions",
          align: "end",
          sortable: true,
        },
        {
          text: "users",
          value: "users",
          align: "end",
          sortable: true,
        },
        {
          text: "avg session duration",
          value: "avg_session_duration",
          align: "end",
          sortable: true,
        },
      ],
      // sites: [],
      loading: false,
      numberOfPages: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  created() {
    this.selectedDates = this.datesInitialValue;
    // this.fetchTables();
    this.fetchSites();
    this.selectedSite = 2;
  },
  computed: {
    // sites() {
    //   return this.$store.getters["premium/getSites"];
    // },
    // dates() {
    //   return this.$store.getters["premium/getDates"];
    // },
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    setDates(dates) {
      // console.log("this.selectedDates = ", dates);
      this.selectedDates = dates;
    },
    tableChange() {
      this.dimensions = this.tablesWithDimensions[this.selectedTable];
      if (this.dimensions.length) {
        this.selectedDimension = this.dimensions[0];
      }
      // console.log("tableChange this.selectedTable", this.selectedTable);
    },
    resetFilters() {
      this.selectedSite = null;
    },
    // async fetchTables() {
    //   try {
    //     const response = await axios.get(
    //       "/universal-analytics/tables-with-dimensions"
    //     );
    //     this.tablesWithDimensions = response.data;
    //     console.log("this.tablesWithDimensions", this.tablesWithDimensions);
    //     // this.tables = Object.keys(this.tablesWithDimensions);
    //     this.tables = Object.keys(this.tablesWithDimensions).map((table) => ({
    //       text: table.replace(/^ua__/, ""), // .charAt(0).toUpperCase() + table.replace(/^ua__/, "").slice(1),
    //       value: table,
    //     }));
    //     console.log("tables", this.tables);
    //   } catch (error) {
    //     console.error("Error fetching tables:", error);
    //   }
    // },
    onTableChange() {
      this.items = [];
      this.headers = [];
    },
    async updateData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let params = {
        table: this.selectedTable,
        sites: this.selectedSite,
        dates: this.selectedDates.join(","),
        //dimension: this.selectedDimension,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };
      let searchStr = this.search.trim();
      if (this.selectedDimension && searchStr.length > 2) {
        params["search"] = searchStr;
        params["dimension"] = this.selectedDimension;
      }

      //console.log("params");
      try {
        this.loading = true;
        const response = await axios.get("/universal-analytics/table-data", {
          params: params,
        });
        this.loading = false;
        response.data.items.forEach((item) => {
          item.views = prettyNbs(item.views);
          item.sessions = prettyNbs(item.sessions);
          item.users = prettyNbs(item.users);
          item.avg_session_duration = prettyNbs(item.avg_session_duration);
        });
        this.items = response.data.items;
        this.options.total = response.data.total;
        this.numberOfPages = response.data.total / this.options.itemsPerPage;
        this.setTableHeaders();
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
      this.loading = false;
    },
    setTableHeaders() {
      if (!(this.items && this.items.length > 0)) return;
      let headers = [...this.headers_dimensions_static];
      let dynamic_dimensions = this.tablesWithDimensions[this.selectedTable];
      // console.log("this.items[0]", this.items[0]);
      dynamic_dimensions.forEach((dimension) => {
        if (dimension !== "source_medium") {
          headers.push({
            text: dimension,
            value: dimension,
            align: "start",
            sortable: false,
          });
        }
      });
      if ("total_events" in this.items[0]) {
        headers.push({
          text: "events",
          value: "total_events",
          align: "start",
          sortable: false,
        });
      }
      headers.push(...this.headers_metrics);
      if (this.selectedTable.includes("path")) {
        headers.push({
          text: "bounce rate",
          value: "bounce_rate",
          align: "start",
          sortable: false,
        });
      }
      this.headers = headers; //Object.keys(this.items[0]);
    },
    async fetchSites() {
      try {
        const response = await axios.get("/universal-analytics/sites");
        this.sites = response.data;
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    },
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.debouncedUpdateData();
    },
    "options.page"() {
      this.debouncedUpdateData();
    },
    "options.sortBy"() {
      this.debouncedUpdateData();
    },
    "options.sortDesc"() {
      this.debouncedUpdateData();
    },
  },
};
</script>

<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>
